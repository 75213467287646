import * as React from "react";
import {
    Box,
    Text,
    Flex,
} from "@chakra-ui/react";

const Footer = ({site}) => {

    const siteLogo = `/logos/${site.logo}`;

    return (
        <footer>
            <Box>
                <Flex mt={50}
                    align={'center'}
                    _before={{
                      content: '""',
                      borderBottom: '1px solid',
                      borderColor: 'lightGray',
                      flexGrow: 1,
                      mr: 8,
                    }}
                    _after={{
                      content: '""',
                      borderBottom: '1px solid',
                      borderColor: 'lightGray',
                      flexGrow: 1,
                      ml: 8,
                    }}>
                    <img src={siteLogo} alt={site.title} width={100} />
                </Flex>
                <Text pt={6} fontSize={'sm'} textAlign={'center'}>
                    &copy; {new Date().getFullYear()} {site.title}. All Rights Reserved
                    &nbsp;&bull;&nbsp;<a target="_blank" href="/privacy/">Privacy Policy</a>
                    &nbsp;&bull;&nbsp;<a target="_blank" href="/terms/">Terms of Use</a>
                </Text>
            </Box>
        </footer>
    );
};

export default Footer;
